// Carbon Design System - Carbon Components Angular
@use '@carbon/styles';

/* You can add global styles to this file, and also import other style files */
@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

@import 'src/assets/scss/theme.scss';
@import 'src/assets/scss/material-theme.scss';
@import 'angular-calendar/scss/angular-calendar';

@font-face {
	font-family: 'Inter';
	src: url('./assets/fonts/Inter.ttf') format('truetype');
	font-weight: normal; // default font weight
	font-style: normal; // default font style
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-Regular.otf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-RegularItalica.otf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-Fina.otf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-FinaItalica.otf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-Ligera.otf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-LigeraItalica.otf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-Negrita.otf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-NegritaItalica.otf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-Negra.otf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Connect';
	src: url('./assets/fonts/Connect-NegraItalica.otf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

$bg-dark-primary: #1f262d;
$bg-dark-secondary: #394046;
$bg-active: #2c343a;
$text-color: #d5d6d7;
$border-color: rgba(0, 0, 0, 0.6);

// Call the calendar mixin with a sass color map of your theme. Every property is optional.
// For a list of all variables and how they are used,
// see https://github.com/mattlewis92/angular-calendar/tree/master/projects/angular-calendar/src/variables.scss
@include cal-theme(
	(
		bg-primary: $bg-dark-primary,
		bg-secondary: $bg-dark-secondary,
		weekend-color: indianred,
		bg-active: $bg-active,
		border-color: $border-color,
		gray: $bg-dark-secondary,
		today-bg: $bg-dark-secondary,
		event-color-primary: $bg-dark-secondary,
	)
);

*,
*:focus,
button:focus {
	outline: none;
}

html {
	top: 0 !important;
	overflow: scroll;
}

body {
	overflow: auto;
}

.cal-event-title {
	color: #ffff !important;
}

.cal-event-title-secondary,
.cal-event-title-secondary > a {
	color: #586983;
}

.owl-dt-calendar-table .owl-dt-calendar-header {
	background: white;
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
	font-size: 0.7em;
	font-weight: 400;
	text-align: center;
	padding-bottom: 1em;
	color: black;
}

#toast-container > div {
	opacity: 1;
}

body {
	font-size: 14px;
	font-family: 'Lato', sans-serif !important;
	overflow-x: hidden;
}

.btn {
	border-radius: 0;
	box-shadow: none;
}
.btn-round {
	border-radius: 90px !important;
	background-color: $primary;
	padding: 4px 10px;
	min-width: 34px;
}

.bg-black {
	background-color: $black;
}

.border-black {
	border-color: $black;
	border-width: 3px;
}

.search-input {
	background-color: white;
	border: 1px solid white;
	border-radius: 0;
}
.search-input ::placeholder {
	color: $dark;
	background-color: white;
}

.btn-input {
	padding-right: 12px;
	padding-left: 12px;
	color: #fff !important;
	height: 31px;
}

.border-bottom-right {
	border-bottom-right-radius: 20px;
}
.card-header-sm {
	height: 30px;
	background-color: $dark-gray;
	padding: 3px 16px;
}
.helios-input {
	background-color: transparent;
	border-radius: 0;
	border: none;
	border-bottom: 3px solid $black;
}
.helios-input:focus {
	background-color: transparent;
	box-shadow: none;
	border-bottom: 3px solid $primary;
}
.helios-input.helios-input-lg {
	height: 65px;
	color: $white;
	font-size: 24px;
}

::placeholder {
	color: $light;
	font-size: 14px;
	font-weight: 300;
}

.bg-dark-gray {
	background-color: $dark-gray;
}

/* Dispatch Client Statuses*/
.Active {
	background-color: #00c4b4;
	padding: 2px 10px;
	color: white;
	border-radius: 0px 0px 8px 0px;
}
.Inactive {
	background-color: #f4b642;
	padding: 2px 10px;
	color: white;
	border-radius: 0px 0px 8px 0px;
}
.Cancelled {
	background-color: #ff0000;
	padding: 2px 10px;
	color: white;
	border-radius: 0px 0px 8px 0px;
}

td {
	text-align: center;
	vertical-align: middle !important;
}

td.tripStatus {
	height: 0px;
	border-width: 0px;
	padding: 0 !important;
}
td.tripStatus > .ui-cell-data {
	display: flex;
	height: 100%;
	width: 100%;
	padding: 0;
}

td.tripStatus > .ui-cell-data > div {
	display: flex;
	height: 100%;
	width: 100%;
	padding: 0;
	justify-content: center;
}
td.tripStatus > .ui-cell-data > div > p {
	align-self: center;
}

// td.status,
// td.status > div {
// 	height: 100%;
// 	padding: 0;
// }
td.status {
	height: 0;
	// width: 100%;
	min-width: 90px;
	max-width: 100px;
	padding: 0;
	div {
		height: 100%;
		display: flex;
		width: 100%;
		padding: 0;
		justify-content: center;
		p {
			align-self: center;
			margin-bottom: 0;
		}
	}
}

th.actions {
	max-width: 325px !important;
}

tr:hover {
	.status {
		color: white;
	}
}

.orangeNew,
.pending,
.active {
	background-color: #fd5a00;
}
.new {
	background-color: #555555;
}
.accepted {
	background-color: #3bda23;
}
.on_route {
	background-color: #00c3b3;
}
.arrived {
	background-color: #f8cd00;
}
.towed {
	background-color: #9c27b0;
}
.finished {
	background-color: #009ce4;
}
.cancelled {
	background-color: #ff0000;
}
.cancelled_by_driver {
	background-color: #ff0000;
}

.busy {
	background-color: #555;
}

.bg-default {
	background-color: #555555;
}
.bg-success {
	background-color: #3bda23;
}
.bg-danger {
	background-color: #ff0000;
}

.bg-info {
	background-color: #009ce4;
}

.bg-primary {
	background-color: #fd5a00;
}

.bg-warning {
	background-color: #f8cd00;
}

.w-button.sm {
	padding: 4px 6px;
	margin: 2px;
}

.w-button.button-modal {
	padding-right: 5px;
	padding-left: 5px;
	width: 100%;
	height: 30px;
	padding-top: 4px;
	padding-bottom: 4px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.roles-modal .slds-modal__content {
	/* slds-p-around--medium */
	min-height: 550px !important;
}

.slds-modal__content,
.slds-modal__header {
	background: transparent !important;
}

.slds-modal__content:last-child {
	box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
}

.slds-modal__close {
	display: none !important;
}
.card {
	border-radius: 0;
	.card-header {
		border-radius: 0;
	}
	.card-body {
		border-radius: 0;
	}
	.card-footer {
		border-radius: 0;
	}
}
.card-header {
	background-color: $dark-gray !important;
}

.card-body,
.card-footer {
	background-color: $dark !important;
}

.form-control-light,
.form-control-light:focus {
	border-radius: 0 !important;
	background-color: white !important;
	border: 1px solid white !important;
}
.form-control-dark,
.form-control-dark:read-only,
.form-control-dark:focus {
	border-radius: 0;
	background-color: $dark-gray;
	border: 1px solid $dark-gray;
	color: white;
}
.form-control-dark.form-control-outline {
	background-color: transparent;
	border: 3px $dark-gray solid;
	font-size: 14px;
	font-weight: 500;
}
.ng-select.custom-light {
	.ng-select-container {
		border-radius: 0;
		background-color: white !important;
		border: 1px solid $white !important;

		.ng-value-container {
			.ng-value {
				.ng-value-icon.left {
					border-right-color: $primary !important;
				}
				border-radius: 0;
				background-color: $light !important;
				border: 1px solid $light !important;
			}
		}
	}
	.ng-dropdown-panel {
		border-color: white;
		.ng-dropdown-panel-items {
			.ng-optgroup,
			.ng-option {
				background-color: white !important;
				border: 1px solid white !important;
			}
		}
	}
}

.ng-select.custom-transparent {
	flex-grow: 1;
	width: 1%;
	.ng-select-container {
		border-radius: 0;
		height: calc(1.5em + 0.75rem + 2px);
		background-color: transparent !important;
		border: none;
		color: $success !important;

		.ng-value-container {
			.ng-value {
				font-size: 17px;
				font-weight: 700;
				height: calc(1.5em + 0.75rem + 2px);
				margin-left: 1px;
				margin-top: 31px;
			}
			.ng-input {
				input {
					font-size: 17px;
					font-weight: 700;
					height: calc(1.5em + 0.75rem + 2px);
				}
			}
			.ng-value {
				.ng-value-icon.left {
					border-right-color: $primary !important;
				}
				// border-radius: 0;
				// background-color: $dark !important;
				// border: 1px solid $dark !important;
				// color: white !important;
			}
		}
		.ng-arrow-wrapper {
			margin-top: 15px;
		}
		.ng-clear-wrapper {
			.ng-clear {
				margin-top: 15px;
			}
		}
	}
	.ng-dropdown-panel {
		background-color: $secondary;
		border-color: transparent;
		.ng-dropdown-panel-items {
			.ng-optgroup,
			.ng-option {
				background-color: $dark-gray !important;
				border: 1px solid $dark-gray !important;
				color: white !important;
			}
		}
	}
}

.ng-select.custom {
	.ng-select-container {
		border-radius: 0;
		background-color: $dark-gray !important;
		border: 1px solid $dark-gray !important;
		color: white !important;

		.ng-value-container {
			.ng-value {
				.ng-value-icon.left {
					border-right-color: $primary !important;
				}
				border-radius: 0;
				background-color: $dark !important;
				border: 1px solid $dark !important;
				color: white !important;
			}
		}
	}
	.ng-dropdown-panel {
		border-color: $dark-gray;
		.ng-dropdown-panel-items {
			.ng-optgroup,
			.ng-option {
				background-color: $dark-gray !important;
				border: 1px solid $dark-gray !important;
				color: white !important;
			}
		}
	}
}

.ng-select.outline {
	.ng-select-container {
		border-radius: 0;
		background-color: transparent !important;
		border: none;
		border-bottom: 2px solid $dark-gray !important;
		color: white;
		box-shadow: none;

		.ng-value-container {
			.ng-value {
				.ng-value-icon.left {
					border-right-color: $primary !important;
				}
				border-radius: 0;
				background-color: $dark !important;
				border: 1px solid $dark !important;
				color: white !important;
			}
		}
	}
	.ng-select-container:focus {
		border-bottom: 2px solid $primary !important;
		border-radius: 0;
		background-color: transparent !important;
		border: none;
		color: white;
		box-shadow: none;
	}
	.ng-dropdown-panel {
		border-color: $dark-gray;
		background-color: $dark-gray;
		.ng-dropdown-panel-items {
			.ng-optgroup,
			.ng-option {
				background-color: $dark-gray !important;
				border: 1px solid $dark-gray !important;
				color: white !important;
			}
		}
	}
}

.table-dashboard {
	font-size: 12px;
	color: #fff !important;
	width: 100%;
}

.table-hover {
	tr:hover {
		color: #fd5a00 !important;
	}
}

@media (max-width: 768px) {
	.title {
		font-size: 38px;
		color: orange;
		font-family: Lato, sans-serif;
		font-weight: 300;
	}
}
@media (min-width: 768px) {
	.title {
		color: orange;
		font-size: 90px;
		font-family: Lato, sans-serif;
		font-weight: 300;
	}
}

.w-33 {
	width: 33.3%;
}
.w-10 {
	width: 10%;
}

.w-90 {
	width: 95%;
}
.w-16 {
	width: 16.5%;
}

.card.card-dispatch {
	border-bottom-right-radius: 20px;
	width: 475px !important;
	// padding-bottom: 10px;
	margin-top: 10px;
	// border-bottom-right-radius: 30px;
	z-index: 999;
	position: relative;
	margin-left: 10px;
	.card-header {
		padding: 8px;
	}
	.card-body {
		border-bottom-right-radius: 20px;
		padding: 8px;
		.form-control-dark,
		.form-control-dark:read-only,
		.form-control-dark:focus {
			margin: 3px 2px;
			border-radius: 0;
			background-color: transparent;
			border: none;
			border-bottom: 2px solid $dark-gray;
			color: white;
			box-shadow: none;
		}
		.form-control-dark:focus {
			border-bottom: 2px solid $primary;
		}

		.ng-select.outline {
			.ng-select-container {
				border-radius: 0;
				background-color: transparent;
				border: none;
				border-bottom: 2px solid $dark-gray;
				color: white;
				box-shadow: none;

				.ng-value-container {
					.ng-value {
						.ng-value-icon.left {
							border-right-color: $primary !important;
						}
						border-radius: 0;
						background-color: $dark !important;
						border: 1px solid $dark !important;
						color: white !important;
					}
				}
			}
			.ng-dropdown-panel {
				border-color: $dark-gray;
				.ng-dropdown-panel-items {
					.ng-optgroup,
					.ng-option {
						background-color: $dark-gray !important;
						border: 1px solid $dark-gray !important;
						color: white !important;
					}
				}
			}
		}
	}
}

.row.sm-gutters {
	.col,
	.col-1,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-10,
	.col-11,
	.col-12 {
		padding-right: 2px;
		padding-left: 2px;
	}
	margin-right: 0px;
	margin-left: 0px;
}

/* Dispatch Client Statuses*/
.Active {
	background-color: #00c4b4;
	padding: 2px 10px;
	color: white;
	border-radius: 0px 0px 8px 0px;
}
.Inactive,
.Waiting-Period {
	background-color: #f4b642;
	padding: 2px 10px;
	color: white;
	border-radius: 0px 0px 8px 0px;
}
.Cancelled,
.Expired {
	background-color: #ff0000;
	padding: 2px 10px;
	color: white;
	border-radius: 0px 0px 8px 0px;
}

td.tripStatus {
	height: 0px;
	border-width: 0px;
	padding: 0 !important;
}
td.tripStatus > .ui-cell-data {
	display: flex;
	height: 100%;
	width: 100%;
	padding: 0;
}

td.tripStatus > .ui-cell-data > div {
	display: flex;
	height: 100%;
	width: 100%;
	padding: 0;
	justify-content: center;
}
td.tripStatus > .ui-cell-data > div > p {
	align-self: center;
}

.orangeNew,
.pending,
.active {
	background-color: #fd5a00;
}
.new {
	background-color: #555555;
}
.accepted {
	background-color: #3bda23;
}
.on_route {
	background-color: #00c3b3;
}
.arrived {
	background-color: #f8cd00;
}
.towed {
	background-color: #9c27b0;
}
.finished {
	background-color: #009ce4;
}
.cancelled {
	background-color: #ff0000;
}
.pending_audit {
	background-color: #6e5ca8;
}

.busy {
	background-color: #555;
}

.bg-default {
	background-color: #555555;
}
.bg-success {
	background-color: #3bda23;
}
.bg-danger {
	background-color: #ff0000;
}

.bg-info {
	background-color: #009ce4;
}

.bg-primary {
	background-color: #fd5a00;
}

.bg-warning {
	background-color: #f8cd00;
}

.w-button.sm {
	padding: 4px 6px;
	margin: 2px;
}

.w-button.button-modal {
	padding-right: 5px;
	padding-left: 5px;
	width: 100%;
	height: 30px;
	padding-top: 4px;
	padding-bottom: 4px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.roles-modal .slds-modal__content {
	/* slds-p-around--medium */
	min-height: 550px !important;
}

.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 10px 16px;
	border-radius: 35px !important;
	font-size: 24px;
	line-height: 1.33;
}

.btn-circle {
	width: 30px;
	height: 30px;
	padding: 6px 0px;
	border-radius: 15px !important;
	text-align: center;
	font-size: 12px;
	line-height: 1.42857;
}
::-webkit-scrollbar {
	width: 12px;
	background-color: $dark-gray;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: $dark-gray;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: $primary;
}
::-webkit-scrollbar-thumb {
	background-color: $secondary;
}

.input-group {
	border: none;
	border-bottom: 3px black solid;
	padding-bottom: 3px;
	select {
		height: 38px;
		font-size: 16px;
	}
}
.input-group-prepend {
	.input-group-text.text-success {
		color: $success !important;
	}

	.input-group-text {
		border-right: 3px black solid;
		text-align: start;
		width: 90px;
	}
	.input-group-text.lg {
		width: 120px;
	}
	.input-group-text.xlg {
		width: 160px;
	}
}
.input-group-text {
	border: none;
	background-color: transparent !important;
	color: #aaa !important;
	font-size: 14px;
	white-space: normal;
	line-height: 1;
}

.form-control-dark.transparent {
	background-color: transparent;
	border-color: transparent;
	color: white;
}

.custom-label {
	width: 40px;
	height: 40px;
	color: #fff;
	font-size: 12px;
	padding-top: 8px;
	text-transform: uppercase;
	border-radius: 50px;
	text-align: center;

	&--stores {
		margin-top: -16px;
	}
}
// ICONSETS
.vial {
	width: 70px;
	height: 28px;
	background-image: url('/assets/images/iconsets/default/vial.svg');
	background-repeat: no-repeat;
}

.wheelLift2Door {
	width: 70px;
	height: 28px;
	background-image: url('/assets/images/iconsets/default/wheelLift2D.svg');
	background-repeat: no-repeat;
}

.wheelLift4Door {
	width: 70px;
	height: 28px;
	background-image: url('/assets/images/iconsets/default/wheelLift4D.svg');
	background-repeat: no-repeat;
}

.flatbed2Door {
	width: 70px;
	height: 28px;
	background-image: url('/assets/images/iconsets/default/flatbed4D.svg');
	background-repeat: no-repeat;
}

.flatbed4Door {
	width: 70px;
	height: 28px;
	background-image: url('/assets/images/iconsets/default/flatbed2D.svg');
	background-repeat: no-repeat;
}

.moto {
	width: 70px;
	height: 28px;
	background-image: url('/assets/images/iconsets/default/moto.svg');
	background-repeat: no-repeat;
}

.stores {
	width: 60px;
	height: 60px;
	background-image: url('/assets/images/iconsets/default/shops.svg');
	background-repeat: no-repeat;
}

/* Sura Icons*/
.vial.iconset-sura {
	background-image: url('/assets/images/iconsets/sura/vial.svg');
}
.wheelLift2Door.iconset-sura {
	background-image: url('/assets/images/iconsets/sura/wheelLift2D.svg');
}
.wheelLift4Door.iconset-sura {
	background-image: url('/assets/images/iconsets/sura/wheelLift4D.svg');
}
.flatbed4Door.iconset-sura {
	background-image: url('/assets/images/iconsets/sura/flatbed4D.svg');
}
.flatbed2Door.iconset-sura {
	background-image: url('/assets/images/iconsets/sura/flatbed2D.svg');
}
.moto.iconset-sura {
	background-image: url('/assets/images/iconsets/sura/moto.svg');
}

/* Qualitas GAM Icons*/
.vial.iconset-qualitas {
	background-image: url('/assets/images/iconsets/qualitas/vial.svg');
}
.wheelLift2Door.iconset-qualitas {
	background-image: url('/assets/images/iconsets/qualitas/wheelLift2D.svg');
}
.wheelLift4Door.iconset-qualitas {
	background-image: url('/assets/images/iconsets/qualitas/wheelLift4D.svg');
}
.flatbed4Door.iconset-qualitas {
	background-image: url('/assets/images/iconsets/qualitas/flatbed4D.svg');
}
.flatbed2Door.iconset-qualitas {
	background-image: url('/assets/images/iconsets/qualitas/flatbed2D.svg');
}
.moto.iconset-qualitas {
	background-image: url('/assets/images/iconsets/qualitas/moto.svg');
}

/* Qualitas Rural Icons*/
.vial.iconset-qualitas_rural {
	background-image: url('/assets/images/iconsets/qualitas/vial.svg');
}
.wheelLift2Door.iconset-qualitas_rural {
	background-image: url('/assets/images/iconsets/qualitas/wheelLift2D.svg');
}
.wheelLift4Door.iconset-qualitas_rural {
	background-image: url('/assets/images/iconsets/qualitas/wheelLift4D.svg');
}
.flatbed4Door.iconset-qualitas_rural {
	background-image: url('/assets/images/iconsets/qualitas/flatbed4D.svg');
}
.flatbed2Door.iconset-qualitas_rural {
	background-image: url('/assets/images/iconsets/qualitas/flatbed2D.svg');
}
.moto.iconset-qualitas_rural {
	background-image: url('/assets/images/iconsets/qualitas/moto.svg');
}

/* CONNECT Icons*/
.vial.iconset-connect {
	background-image: url('/assets/images/iconsets/connect/vial.svg');
}
.wheelLift2Door.iconset-connect {
	background-image: url('/assets/images/iconsets/connect/wheelLift2D.svg');
}
.wheelLift4Door.iconset-connect {
	background-image: url('/assets/images/iconsets/connect/wheelLift4D.svg');
}
.flatbed2Door.iconset-connect {
	background-image: url('/assets/images/iconsets/connect/flatbed2D.svg');
}
.flatbed4Door.iconset-connect {
	background-image: url('/assets/images/iconsets/connect/flatbed4D.svg');
}
.moto.iconset-connect {
	background-image: url('/assets/images/iconsets/connect/moto.svg');
}

/* Vanti icons */
.moto.iconset-vanti {
	background-image: url('/assets/images/iconsets/vanti/moto.svg');
}

.management-search-group {
	@extend .input-group;
	border-bottom: none;
}

.info-color {
	color: $info;
}

.pt-1 {
	padding-top: 1rem;
}

.custom-dialog-container-follow-up .mat-dialog-container {
	padding: 0;
}

.bold {
	font-weight: bold;
}

.tag {
	margin-top: 5px;
	border-radius: 1rem;
	padding: 3px 10px;
	width: fit-content;
	background-color: #5d5d5d;
	color: #fff;
	font-weight: 600;
	text-align: center;
	&.green {
		background-color: #50c878;
		color: #fff;
	}
	&.dark-grey {
		background-color: #3a3534;
	}
}

.ml-10px {
	margin-left: 10px;
}

.turquoise-link {
	color: $turquoise !important;
	text-decoration: underline !important;
	cursor: pointer;
}

.grid {
	display: grid;
}

.surplus-container {
	margin-top: 1.25rem;
	margin-left: 0.625rem;
	margin-right: 0.625rem;
}
