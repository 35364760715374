$danger: #e01f25;
$turquoise: #02ECD5;
$dark-gray: #181818;
$dark: #282828;
$darkish-gray: #333;
$emergency-color: #db4437;
$info: #00c4b3;
$light-gray: #939393;
$light: #e8e8e8;
$primary: #ff5100;
$error: #F44336;
$secondary: #58595b;
$success: #70d44b;
$warning: #FFB41F;
$light: #e8e8e8;
$dark: #282828;
$dark-gray: #181818;
$darkish-gray: #333;
$light-gray: #939393;
$emergency-color: #db4437;
$accent: #FFA500;
$white: #FFF;
$dark-grayish-blue: #58595b;
$darkish-gray-blue: #757679;
$soft-blue: #5cc4f7;
$light-grayish-blue: #e5f6fd;
$very-dark-blue: #014361;
$very-dark-desaturated-lime-green: #1e4620;
$light-grayish-lime-green: #edf7ed;
$slightly-desaturated-lime-green: #5cb660;
$very-pale-orange: #fff4e5;
$very-dark-orange: #663C00;
$vivid-orange: #FFA117;
$sight-grayish-red: #fdeded;
$very-dark-red: #5f2120;
$soft-red: #F06360;
$softGray: #F5F5F5;

// Design System Color Palette
$blue-50: #F9FCFF;
$blue-100: #EEF7FF;
$blue-200: #CADAE8;
$blue-300: #A3BED6;
$blue-350: #586983;
$blue-400: #3C546E;
$blue-500: #001D3D;
$blue-500-overlay: #001D3D5C;

$orange-50: #FFFAF9;
$orange-100: #FFF2EE;
$orange-200: #FCCCBD;
$orange-300: #F8A78D;
$orange-400: #F4815C;
$orange-500: #F15B2B;
$orange-500-overlay: #F15B2B8C;

$white: #FFFFFF;
$salmon: #F98965;

$gray-25: #F9F9F9;
$gray-50: #F2F2F2;
$gray-100: #E5E5E5;
$gray-300: #000000;

$alert-error: $error;
$alert-error-light: #FFF6F6;
$alert-ds-error: #EC4C41;
$alert-success: #43A047;
$alert-success-light: #ECF8E8;
$alert-warning: $warning;
$alert-warning-light: #FFF8EA;
$alert-info: #2979FF;
$alert-info-light: #EBF2FF;

$moonstone-blue: #74BfB4;

$backgrounds-light-blue: #F8FCFF;
$hover-light-blue: #F8FCFF;

$errorIcon: url('/assets/images/alert_icon-error.svg');
$warningIcon: url('/assets/images/alert_icon-warning.svg');
$successIcon: url('/assets/images/alert_icon-success.svg');

// Font mixins
@mixin desktop-h3 {
  font-family: 'Connect', sans-serif;
	font-size: 48px;
	font-weight: 1000;
	line-height: 48px;
	letter-spacing: 0px;
}

@mixin desktop-h4 {
  font-family: 'Connect', sans-serif;
	font-size: 36px;
	font-weight: 700;
	line-height: 48px;
	letter-spacing: 0px;
	text-align: left;
}

@mixin desktop-h5 {
  font-family: 'Connect', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.045em;
  text-align: left;
}

@mixin desktop-h6 {
  font-family: 'Connect', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.8px;
}

@mixin desktop-p1-regular {
  font-family: 'Connect', sans-serif;
  font-size: 24px;
  font-weight: 200;
  line-height: 32px;
  letter-spacing: 0.8px;
}

@mixin desktop-p2-regular {
  font-family: 'Connect', sans-serif;
  font-size: 18px;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0.800000011920929px;
  text-align: left;
}

@mixin desktop-p4-regular {
  font-family: 'Connect', sans-serif;
  font-size: 14px;
  font-weight: 200;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.8px;
}

@mixin desktop-p5-regular {
  font-family: 'Connect', sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 16px; /* 128.571% */
  letter-spacing: 0.8px;
}

@mixin desktop-p1-bold {
  font-family: 'Connect', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.8px;
}
